import { Injectable, OnDestroy } from '@angular/core';
import { Subject, BehaviorSubject, fromEvent } from 'rxjs';
import { takeUntil, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';

// Menu
export interface Menu {
	headTitle1?: string,
	headTitle2?: string,
	path?: string;
	title?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService implements OnDestroy {

	private unsubscriber: Subject<any> = new Subject();
	public screenWidth: BehaviorSubject<number> = new BehaviorSubject(window.innerWidth);

	// Search Box
	public search: boolean = false;

	// Language
	public language: boolean = false;

	// Mega Menu
	public megaMenu: boolean = false;
	public levelMenu: boolean = false;
	public megaMenuColapse: boolean = window.innerWidth < 1199 ? true : false;

	// For Horizontal Layout Mobile
	public horizontal: boolean = window.innerWidth < 991 ? false : true;

	// Collapse Sidebar
	public collapseSidebar: boolean = window.innerWidth < 991 ? true : false;

	// Full screen
	public fullScreen: boolean = false;

	constructor(private router: Router) {
		this.setScreenWidth(window.innerWidth);
		fromEvent(window, 'resize').pipe(
			debounceTime(1000),
			takeUntil(this.unsubscriber)
		).subscribe((evt: any) => {
			this.setScreenWidth(evt.target.innerWidth);
			if (evt.target.innerWidth < 991) {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			}
			if (evt.target.innerWidth < 1199) {
				this.megaMenuColapse = true;
			}
		});
		if (window.innerWidth < 991) { // Detect Route change sidebar close
			this.router.events.subscribe(event => {
				this.collapseSidebar = true;
				this.megaMenu = false;
				this.levelMenu = false;
			});
		}
	}

	ngOnDestroy() {
		this.unsubscriber.next();
		this.unsubscriber.complete();
	}

	private setScreenWidth(width: number): void {
		this.screenWidth.next(width);
	}

	SUPERADMINMENUITEMS: Menu[] = [
		// {
		// 	headTitle1: 'Sample', headTitle2: 'Ready Sample Page.',
		// },
		{ path: '/dashboard', title: 'Dashboard', icon: 'check-square', type: 'link' },
		{ path: '/desktop-section', title: 'Desktop', icon: 'check-square', type: 'link' },

		{ path: '/masters/jobs', title: 'Jobs', icon: 'box', type: 'link' },
		{ path: '/calender', title: 'Calender', icon: 'box', type: 'link' },
		{ path: '/masters/clients', title: 'Clients', icon: 'users', type: 'link' },
		{ path: '/leave-approval', title: 'Leave Approval', icon: 'box', type: 'link' },
		{ path: '/staff-log-approval', title: 'Staff Log Approval', icon: 'box', type: 'link' },
		{ path: '/update-staff-logs', title: 'Update Staff Logs', icon: 'box', type: 'link' },

		{ path: '/completed-jobs', title: 'Compeleted Jobs', icon: 'box', type: 'link' },
		{ path: '/push-notifications', title: 'Push Notifcations', icon: 'box', type: 'link' },

		{ path: '/vehicle-logs-update', title: 'Update Vehicle Log', icon: 'box', type: 'link' },
		{ path: '/tool-logs-update', title: 'Update Tool Log', icon: 'box', type: 'link' },
		// { path: '/job-staff-multiple-mapping', title: '', icon: 'box', type: 'link' },
		{ path: '/assigned-job-staff-mappings', title: 'JOB-STAFF', icon: 'box', type: 'link' },








		{
			title: 'Reports', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/reports/staff-log-report', title: 'Staff Log Report', type: 'link' },
				{ path: '/reports/vehicle-log-report', title: 'Vehicle Log Report', type: 'link' },
				{ path: '/reports/tool-log-report', title: 'Tool Log Report', type: 'link' },


				{ path: '/reports/jobs-by-staff-report', title: 'Job Report by Staff', type: 'link' },
				{ path: '/reports/jobs-by-vehicle-report', title: 'Job Report by Vehicle', type: 'link' },
				{ path: '/reports/jobs-by-tool-report', title: 'Job Report by Tool', type: 'link' },

				{ path: '/reports/jobs-by-team-report', title: 'Job Report by Team', type: 'link' },
				{ path: '/reports/work-log-report', title: 'Work Log Report', type: 'link' },
				{ path: '/reports/work-log-report-detailed', title: 'Work Log Report Detailed', type: 'link' },

				{ path: '/reports/staff-leave-report', title: 'Staff Leave Report', type: 'link' },
				{ path: '/reports/staff-rating-report', title: 'Staff Rating Report', type: 'link' },




			]
		},
		{
			title: 'Configurations', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/masters/staffs', title: 'Staff', type: 'link' },
				{ path: '/masters/users', title: 'Users', type: 'link' },
				{ path: '/masters/locations', title: 'Locations', type: 'link' },
				{ path: '/masters/vehicles', title: 'Vehicles', type: 'link' },
				{ path: '/masters/teams', title: 'Teams', type: 'link' },
				{ path: '/masters/tools', title: 'Tools', type: 'link' },
				{ path: '/masters/notifications', title: 'Notifications', type: 'link' },
			]
		},
	];
	OPERATORMENUITEMS: Menu[] = [
		// {
		// 	headTitle1: 'Sample', headTitle2: 'Ready Sample Page.',
		// },
		{ path: '/dashboard', title: 'Dashboard', icon: 'check-square', type: 'link' },
		{ path: '/desktop-section', title: 'Desktop', icon: 'check-square', type: 'link' },
		{ path: '/calender', title: 'Calender', icon: 'box', type: 'link' },

		{ path: '/masters/jobs', title: 'Jobs', icon: 'box', type: 'link' },
		{ path: '/masters/clients', title: 'Clients', icon: 'users', type: 'link' },







		{
			title: 'Reports', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/reports/staff-log-report', title: 'Staff Log Report', type: 'link' },
				{ path: '/reports/vehicle-log-report', title: 'Vehicle Log Report', type: 'link' },
				{ path: '/reports/tool-log-report', title: 'Tool Log Report', type: 'link' },


				{ path: '/reports/jobs-by-staff-report', title: 'Job Report by Staff', type: 'link' },
				{ path: '/reports/jobs-by-vehicle-report', title: 'Job Report by Vehicle', type: 'link' },
				{ path: '/reports/jobs-by-tool-report', title: 'Job Report by Tool', type: 'link' },

				{ path: '/reports/jobs-by-team-report', title: 'Job Report by Team', type: 'link' },
				{ path: '/reports/work-log-report', title: 'Work Log Report', type: 'link' },
				{ path: '/reports/work-log-report-detailed', title: 'Work Log Report Detailed', type: 'link' },

				{ path: '/reports/staff-leave-report', title: 'Staff Leave Report', type: 'link' },




			]
		},
		{
			title: 'Configurations', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/masters/staffs', title: 'Staff', type: 'link' },
				// { path: '/masters/users', title: 'Users', type: 'link' },
				{ path: '/masters/locations', title: 'Locations', type: 'link' },
				{ path: '/masters/vehicles', title: 'Vehicles', type: 'link' },
				{ path: '/masters/teams', title: 'Teams', type: 'link' },
				{ path: '/masters/tools', title: 'Tools', type: 'link' },
				{ path: '/masters/notifications', title: 'Notifications', type: 'link' },

			]
		},
	];
	STOCKADMINMENUITEMS: Menu[] = [
		// {
		// 	headTitle1: 'Sample', headTitle2: 'Ready Sample Page.',
		// },
		{ path: '/masters/inventory/inventory-dashboard', title: 'Dashboard', icon: 'check-square', type: 'link' },

		{ path: '/masters/inventory/product/product-view', title: 'Product View', type: 'link', icon: 'box' },
	
		{ path: '/masters/inventory/stock', title: 'Stock', type: 'link', icon: 'box' },
		{ path: '/reports/staff-stock-log-view', title: 'Stock Log View', type: 'link', icon: 'box' },
		{ path: '/reports/staff-multi-stock-log-view', title: 'Multi Stock Log View', type: 'link', icon: 'box' },
		{ path: '/masters/inventory/staff-stock-log/un-approved-stock-logs-consolidated', title: 'Stock Log Approval', type: 'link', icon: 'box' },
		{ path: '/masters/inventory/purchase-order', title: 'Purchase Invoice', type: 'link', icon: 'box' },
		{ path: '/masters/inventory/stock-adjustment', title: 'Stock Adjustment', type: 'link', icon: 'box' },
		{ path: '/masters/inventory/stock-transfer', title: 'Stock Transfer', type: 'link', icon: 'box' },

		{
			title: 'Configurations', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/masters/inventory/store', title: 'Store', type: 'link', icon: 'box' },
				// { path: '/masters/inventory/storage', title: 'Storage', type: 'link', icon: 'box' },
				{ path: '/masters/inventory/product-category', title: 'Product Category', type: 'link', icon: 'box' },
				{ path: '/masters/inventory/supplier', title: 'Supplier', type: 'link', icon: 'box' },
				{ path: '/masters/inventory/product', title: 'Products', type: 'link', icon: 'box' },


			]
		},
		{
			title: 'Reports', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/reports/purchase-order-report', title: 'Purchase Order Report', type: 'link', icon: 'box' },
				{ path: '/reports/stock-adjustment-report', title: 'Stock Adjustment Report', type: 'link', icon: 'box' },
				{ path: '/reports/staff-stock-log-report', title: 'Staff Stock Log Report', type: 'link', icon: 'box' },
				{ path: '/reports/product-stock-report', title: 'Product Stock Report', type: 'link', icon: 'box' },
			]
		},
	];
	STOCKMANAGERMENUITEMS: Menu[] = [
		// {
		// 	headTitle1: 'Sample', headTitle2: 'Ready Sample Page.',
		// },
		{ path: '/masters/inventory/inventory-dashboard', title: 'Dashboard', icon: 'check-square', type: 'link' },

		{ path: '/masters/inventory/product/product-view', title: 'Product View', type: 'link', icon: 'box' },
	
		{ path: '/masters/inventory/stock', title: 'Stock', type: 'link', icon: 'box' },
		{ path: '/reports/staff-stock-log-view', title: 'Stock Log View', type: 'link', icon: 'box' },
		{ path: '/reports/staff-multi-stock-log-view', title: 'Multi Stock Log View', type: 'link', icon: 'box' },

		// { path: '/masters/inventory/staff-stock-log/un-approved-stock-logs-consolidated', title: 'Stock Log Approval', type: 'link', icon: 'box' },
		{ path: '/masters/inventory/purchase-order', title: 'Purchase Invoice', type: 'link', icon: 'box' },
		// { path: '/masters/inventory/stock-adjustment', title: 'Stock Adjustment', type: 'link', icon: 'box' },
		{ path: '/masters/inventory/close-stock-log', title: 'Close Stock Log', type: 'link', icon: 'box' },

		// {
		// 	title: 'Configurations', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
		// 		{ path: '/masters/inventory/store', title: 'Store', type: 'link', icon: 'box' },
		// 		// { path: '/masters/inventory/storage', title: 'Storage', type: 'link', icon: 'box' },
		// 		{ path: '/masters/inventory/product-category', title: 'Product Category', type: 'link', icon: 'box' },
		// 		{ path: '/masters/inventory/supplier', title: 'Supplier', type: 'link', icon: 'box' },
		// 		{ path: '/masters/inventory/product', title: 'Products', type: 'link', icon: 'box' },


		// 	]
		// },
		{
			title: 'Reports', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/reports/purchase-order-report', title: 'Purchase Order Report', type: 'link', icon: 'box' },
				{ path: '/reports/stock-adjustment-report', title: 'Stock Adjustment Report', type: 'link', icon: 'box' },
				{ path: '/reports/staff-stock-log-report', title: 'Staff Stock Log Report', type: 'link', icon: 'box' },
				{ path: '/reports/product-stock-report', title: 'Product Stock Report', type: 'link', icon: 'box' },
			]
		},
	];
	STOCKOPERATORMENUITEMS: Menu[] = [
		// {
		// 	headTitle1: 'Sample', headTitle2: 'Ready Sample Page.',
		// },
		{ path: '/masters/inventory/inventory-dashboard', title: 'Dashboard', icon: 'check-square', type: 'link' },

		{ path: '/masters/inventory/product/product-view', title: 'Product View', type: 'link', icon: 'box' },
		{ path: '/masters/inventory/stock', title: 'Stock', type: 'link', icon: 'box' },
		{ path: '/masters/inventory/stock-log-menu', title: 'Stock Log', icon: 'box', type: 'link' },
		{ path: '/reports/staff-stock-log-view', title: 'Stock Log View', type: 'link', icon: 'box' },
		{ path: '/reports/staff-multi-stock-log-view', title: 'Multi Stock Log View', type: 'link', icon: 'box' },
		{ path: '/masters/inventory/staff-stock-log/un-approved-stock-logs-consolidated', title: 'Stock Log Approval', type: 'link', icon: 'box' },
		{ path: '/masters/inventory/purchase-order', title: 'Purchase Invoice', type: 'link', icon: 'box' },
		// { path: '/masters/inventory/stock-adjustment', title: 'Stock Adjustment', type: 'link', icon: 'box' },

		// {
		// 	title: 'Configurations', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
		// 		{ path: '/masters/inventory/store', title: 'Store', type: 'link', icon: 'box' },
		// 		// { path: '/masters/inventory/storage', title: 'Storage', type: 'link', icon: 'box' },
		// 		{ path: '/masters/inventory/product-category', title: 'Product Category', type: 'link', icon: 'box' },
		// 		{ path: '/masters/inventory/supplier', title: 'Supplier', type: 'link', icon: 'box' },
		// 		{ path: '/masters/inventory/product', title: 'Products', type: 'link', icon: 'box' },


		// 	]
		// },
		{
			title: 'Reports', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/reports/purchase-order-report', title: 'Purchase Order Report', type: 'link', icon: 'box' },
				{ path: '/reports/stock-adjustment-report', title: 'Stock Adjustment Report', type: 'link', icon: 'box' },
				{ path: '/reports/staff-stock-log-report', title: 'Staff Stock Log Report', type: 'link', icon: 'box' },
				{ path: '/reports/product-stock-report', title: 'Product Stock Report', type: 'link', icon: 'box' },
			]
		},
	];
	STAFFMENUITEMS: Menu[] = [
		// {
		// 	headTitle1: 'Sample', headTitle2: 'Ready Sample Page.',
		// },
		{ path: '/staff-dashboard', title: 'Dashboard', icon: 'box', type: 'link' },
		{ path: '/masters/inventory/product/product-view', title: 'Product View', type: 'link', icon: 'box' },
		{ path: '/masters/inventory/stock', title: 'Stock', type: 'link', icon: 'box' },

		// { path: '/masters/inventory/staff-stock-logs', title: 'Stock Log', icon: 'box', type: 'link' },
		// { path: '/masters/inventory/client-stock-log', title: 'Ticket Log', icon: 'box', type: 'link' },
		{ path: '/masters/inventory/stock-log-menu', title: 'Stock Log', icon: 'box', type: 'link' },
		// { path: '/staff-log-approval', title: 'Staff Log Approval', icon: 'box', type: 'link' },

		{ path: '/masters/inventory/staff-stock-log/un-approved-stock-logs-consolidated', title: 'Stock Log Approval', type: 'link', icon: 'box' },
		// { path: '/masters/inventory/close-stock-log', title: 'Close Stock Log', type: 'link', icon: 'box' },

		{
			title: 'Reports', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/reports/staff-log-report', title: 'Staff Log Report', type: 'link' },
				{ path: '/reports/vehicle-log-report', title: 'Vehicle Log Report', type: 'link' },
				{ path: '/reports/tool-log-report', title: 'Tool Log Report', type: 'link' },


				{ path: '/reports/jobs-by-staff-report', title: 'Job Report by Staff', type: 'link' },
				{ path: '/reports/jobs-by-vehicle-report', title: 'Job Report by Vehicle', type: 'link' },
				{ path: '/reports/jobs-by-tool-report', title: 'Job Report by Tool', type: 'link' },

				{ path: '/reports/jobs-by-team-report', title: 'Job Report by Team', type: 'link' },





			]
		},


	];
	HRSTAFFMENUITEMS: Menu[] = [
		// {
		// 	headTitle1: 'Sample', headTitle2: 'Ready Sample Page.',
		// },
		{ path: '/staff-dashboard', title: 'Dashboard', icon: 'box', type: 'link' },
		{ path: 'https://dashboard.netnnetsolutions.com/hr/', title: 'HR', icon: 'users', type: 'extLink' },
		{ path: '/calender', title: 'Calender', icon: 'box', type: 'link' },

		{ path: '/leave-approval', title: 'Leave Approval', icon: 'box', type: 'link' },
		{ path: '/staff-log-approval', title: 'Staff Log Approval', icon: 'box', type: 'link' },
		{
			title: 'Reports', icon: 'message-square', type: 'sub', badgeType: 'success', children: [
				{ path: '/reports/staff-log-report', title: 'Staff Log Report', type: 'link' },
				{ path: '/reports/vehicle-log-report', title: 'Vehicle Log Report', type: 'link' },
				{ path: '/reports/tool-log-report', title: 'Tool Log Report', type: 'link' },


				{ path: '/reports/jobs-by-staff-report', title: 'Job Report by Staff', type: 'link' },
				{ path: '/reports/jobs-by-vehicle-report', title: 'Job Report by Vehicle', type: 'link' },
				{ path: '/reports/jobs-by-tool-report', title: 'Job Report by Tool', type: 'link' },

				{ path: '/reports/jobs-by-team-report', title: 'Job Report by Team', type: 'link' },





			]
		},


	];

	MEGAMENUITEMS: Menu[] = [
		{
			title: 'Error Pages', type: 'sub', active: true, children: [
				{ path: 'javascript:void(0);', title: 'Error Page 400', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 401', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 403', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 404', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 500', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Error Page 503', type: 'extLink' },
			]
		},
		{
			title: 'Authentication', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Login Simple', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Login BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Login BG Video', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Simple Register', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Register BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Register BG Video', type: 'extLink' }
			]
		},
		{
			title: 'Usefull Pages', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Search Pages', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Unlock User', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Forgot Password', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Reset Password', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Maintenance', type: 'extLink' }
			]
		},
		{
			title: 'Email templates', type: 'sub', active: false, children: [
				{ path: 'http://admin.pixelstrap.com/NNPortalCMS/theme/basic-template.html', title: 'Basic Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/NNPortalCMS/theme/email-header.html', title: 'Basic With Header', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/NNPortalCMS/theme/template-email.html', title: 'Ecomerce Template', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/NNPortalCMS/theme/template-email-2.html', title: 'Email Template 2', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/NNPortalCMS/theme/ecommerce-templates.html', title: 'Ecommerce Email', type: 'extTabLink' },
				{ path: 'http://admin.pixelstrap.com/NNPortalCMS/theme/email-order-success.html', title: 'Order Success', type: 'extTabLink' }
			]
		},
		{
			title: 'Coming Soon', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'Coming Simple', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Coming BG Image', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Coming BG Video', type: 'extLink' }
			]
		},
	];

	LEVELMENUITEMS: Menu[] = [
		{
			path: 'javascript:void(0);', title: 'File Manager', icon: 'git-pull-request', type: 'extLink'
		},
		{
			title: 'Users', icon: 'users', type: 'sub', active: false, children: [
				{ path: 'javascript:void(0);', title: 'All Users', icon: 'users', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'User Profile', icon: 'users', type: 'extLink' },
				{ path: 'javascript:void(0);', title: 'Edit Profile', icon: 'users', type: 'extLink' },
			]
		},
		{ path: 'javascript:void(0);', title: 'Bookmarks', icon: 'heart', type: 'extLink' },
		{ path: 'javascript:void(0);', title: 'Calender', icon: 'calendar', type: 'extLink' },
		{ path: 'javascript:void(0);', title: 'Social App', icon: 'zap', type: 'extLink' }
	];

	// Array
	items = new BehaviorSubject<Menu[]>([]);
	megaItems = new BehaviorSubject<Menu[]>(this.MEGAMENUITEMS);
	levelmenuitems = new BehaviorSubject<Menu[]>(this.LEVELMENUITEMS);

}
